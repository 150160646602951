import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Empty, Skeleton } from 'antd';
import useSWR from 'swr';
import { useTranslation } from 'react-i18next';

import { API_URL, fetcher, USER_ROLE } from '@utils';
import { OnboardingVideoV2 } from '@components/commonComponents/OnboardingVideo';
import { StudentModalManager } from '@components/commonComponents/Modals';
import { useStudents } from '@hooks';
import { useAppState } from '@context';
import { CardBlock } from '../CardBlock';
import { PasswordModal } from './PasswordModal';

import rightArrow from '@assets/images/rightArrow.svg';

import './LoginDetails.scss';

const NoStudentFound = ({ openModal, setOpenModal }) => {
  const closeModal = () => {
    setOpenModal('');
  };

  return (
    <>
      <CardBlock className="login-details cardsStyling">
        <OnboardingVideoV2
          playing
          type="AddingStudents"
          title="Login Details"
          messageText="You have not added any students and therefore have no login details to view."
          videoFirst={false}
          link={
            <button
              className="dashboard-link"
              onClick={() => {
                setOpenModal('stepOne');
              }}>
              Add students &nbsp;
              <img src={rightArrow} alt="rightArrow" />
            </button>
          }
        />
      </CardBlock>
      {<StudentModalManager closeFn={closeModal} modal={openModal} setModal={setOpenModal} />}
    </>
  );
};

NoStudentFound.propTypes = {
  openModal: PropTypes.string.isRequired,
  setOpenModal: PropTypes.func.isRequired
};

export const LoginDetails = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'overview' });
  const { state } = useAppState();
  const { currentUser } = state;
  const userRole = currentUser?.type;

  const [currentPage, setCurrentPage] = useState(1);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [selectedLoginDetails, setSelectedLoginDetails] = useState({});
  const [openModal, setOpenModal] = useState('');

  const { data: students } = useStudents(state.classId);

  const url =
    userRole === USER_ROLE.PARENT
      ? `${API_URL.PARENT_CHILDREN_PAGE}?page=${currentPage}`
      : `${API_URL.TEACHER_STUDENTS_PAGE}?page=${currentPage}`;

  const { data, isLoading, error, mutate } = useSWR(url, fetcher);

  const { count, results } = data?.data || { count: 0, results: [] };
  const isEmpty = count === 0;

  const totalPage = Array(Math.ceil(count / 5)).fill(1);
  const hasStudent = students && students.length > 0;

  const handleOnClick = student => {
    setSelectedLoginDetails(student);
    setShowPasswordModal(true);
  };

  const handlePage = page => {
    setCurrentPage(page);
  };

  useEffect(() => {
    mutate();
  }, [students.length]);

  if (isLoading) {
    return (
      <CardBlock className="login-details cardsStyling">
        <Skeleton active />
      </CardBlock>
    );
  }

  if ((isEmpty && hasStudent) || error) {
    return (
      <CardBlock className="login-details cardsStyling">
        <h3 className="fixCardHeadingStyle">Login Details</h3>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No links available.'} />
      </CardBlock>
    );
  }

  if (isEmpty && !hasStudent) {
    return <NoStudentFound openModal={openModal} setOpenModal={setOpenModal} />;
  }

  return (
    <>
      <CardBlock bordered={false} className="login-details cardsStyling">
        <h3 className="fixCardHeadingStyle">{t('login_details')}</h3>
        <p className="mb-4">
          {userRole === USER_ROLE.PARENT
            ? 'Click your children’s name below to see their login credentials.'
            : t('click_student_name')}
        </p>
        <Divider className="dividerBilling" />
        <div className="results">
          {results?.map(item => {
            return (
              <div key={`${item.first_name}_${item.id}`}>
                <div className="spaceBetweenDiv" role="button" onClick={() => handleOnClick(item)}>
                  <span className="expStyle">
                    {item.first_name} {item.last_name}
                  </span>
                </div>
                <Divider className="dividerBilling" />
              </div>
            );
          })}
        </div>
        <div className="pagination">
          {totalPage.map((item, index) => {
            return (
              <button
                key={`${index + 1}`}
                className={`pagination-item ${index + 1 === currentPage ? 'current' : ''}`}
                onClick={() => handlePage(index + 1)}>
                {item}
              </button>
            );
          })}
        </div>
      </CardBlock>

      <PasswordModal
        show={showPasswordModal}
        handleOnCancel={() => setShowPasswordModal(false)}
        selectedLoginDetails={selectedLoginDetails}
      />
    </>
  );
};
