import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Form, message } from 'antd';
import { EducatorButton, EducatorInputField, HelperBot } from '../../commonComponents';
import { Navbar, MultistepParent } from '../../layoutComponents';
import lineImage from '../../../assets/images/Line.png';
import { setToken, setFlowComplete } from '../../../utils';
import './parentEmailConformation.css';

export const ParentEmailConformation = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const [code, setCode] = useState('');
  const [buttonSpinner, setButtonSpinner] = useState(false);

  const { password, username } = location.state || {};
  const confirmationSignUp = () => {
    setButtonSpinner(true);
    Auth.confirmSignUp(username, code)
      .then(() => {
        signInUser();
      })
      .catch(err => {
        const errorMessage = `1 validation error detected: Value '${code}' at 'confirmationCode' failed to satisfy constraint: Member must satisfy regular expression pattern: [\\S]+`;
        if (err.message === errorMessage) {
          message.error('Please remove extra spaces');
        } else {
          message.error(`${err.message}`);
        }

        setButtonSpinner(false);
      });
  };

  const signInUser = () => {
    Auth.signIn({
      username: username,
      password: password
    })
      .then(() => {
        setFlowComplete(false);
        saveToken();
      })
      .catch(() => {
        setButtonSpinner(false);
      });
  };
  const saveToken = async () => {
    await Auth.currentSession()
      .then(data => {
        setToken(data.accessToken.jwtToken);
        setButtonSpinner(false);
        navigate('/parent-create-account/child-setup');
      })
      .catch(() => {});
  };

  const resendCode = () => {
    Auth.resendSignUp(username)
      .then(() => {
        message.success('Code resent successfully');
      })
      .catch(err => {
        message.error(`${err.message}`);
      });
  };

  return (
    <div className="mainDivStyle container-fluid">
      <Navbar />
      <div className="row py-5 mt-5">
        <div className="col-3  d-flex justify-content-center">
          <MultistepParent select="Personal Details" check={[0, 0, 0, 0, 0]} />
        </div>
        <div className="col-6 col-md-5 col-lg-6  d-flex justify-content-center">
          <div className="d-flex align-items-center flex-column">
            <div className="emailCodeStyleHeading">Check your email for a 6 digit code.</div>
            <div className="pt-3 textStyle">
              Before we continue we’ll need you to verify your email using the 6 digit code we sent to your email.
              Didn&apos;t get it?
              <span
                className="resendCodeStyle cursorPointer"
                onClick={() => {
                  resendCode();
                }}>
                &nbsp;Resend code
              </span>
              <div className="imageDiv ">
                <img className="imageResendCodeStyle mr-5" src={lineImage} alt="line" width="102" height="0" />
              </div>
            </div>
            <Form onFinish={confirmationSignUp}>
              <Form.Item className="pt-3" name="code" rules={[{ required: true, message: 'Please enter your code!' }]}>
                <EducatorInputField
                  label="Enter Your Code:"
                  type="text"
                  name="code"
                  inputValue={code}
                  onChange={e => setCode(e.target.value)}
                />
              </Form.Item>

              <Form.Item className="pt-3">
                <EducatorButton label="Continue" type="submit" showSpinner={buttonSpinner} />
              </Form.Item>
            </Form>
          </div>
        </div>
        <div className="col-3 d-flex align-items-end mt-2 heightBabyEmailConfirmation">
          <HelperBot>This is just a temporary code to verify your email.</HelperBot>
        </div>
      </div>
    </div>
  );
};
export default ParentEmailConformation;
