import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { QuestionCircleFilled } from '@ant-design/icons';
import { Tooltip } from 'antd';
import dayjs from 'dayjs';
import { Edit } from 'react-feather';

import { Table, Stack, Link, Colors, Badge, Text } from '@teacher-app/design-system';
import { getAssignmentStatus } from '@teacher-app/utils/getAssignmentStatus';

const columns = (t, viewAssignmentReport, editAssignmentId) => [
  {
    key: 'assignment_name',
    title: t('assignment_table_name'),
    dataIndex: 'name',
    width: 220
  },
  {
    key: 'start_date',
    title: t('assignment_start_date'),
    dataIndex: 'start_date',
    render: (_, record) => dayjs(record?.start_date).format('MMM D'),
    sorter: (a, b) => a?.start_date?.localeCompare(b?.start_date)
  },
  {
    title: t('assignment_end_date'),
    dataIndex: 'end_date',
    key: 'end_date',
    render: (_, record) => dayjs(record?.end_date).format('MMM D'),
    sorter: (a, b) => a?.end_date?.localeCompare(b?.end_date)
  },
  {
    key: 'progress',
    title: (
      <>
        {t('progress')}&nbsp;
        <Tooltip
          color="purple"
          title={
            <Text size="x-small" style={{ fontSize: 12, color: Colors.WHITE_0 }}>
              {t('assignment_progress_tooltip')}
            </Text>
          }>
          <QuestionCircleFilled style={{ color: '#D4DFFC', fontSize: '16px' }} />{' '}
        </Tooltip>
      </>
    ),
    render: (_, record) => `${record?.assignments_completed}/${record?.assignments_total}`
  },
  {
    key: 'status',
    title: (
      <>
        {t('status')}&nbsp;
        <Tooltip
          color="purple"
          title={
            <ul style={{ margin: 0 }}>
              <li>
                <Text size="x-small" style={{ fontSize: 12, color: Colors.WHITE_0 }}>
                  {t('assignment_starting_soon')}
                </Text>
              </li>
              <li>
                <Text size="x-small" style={{ fontSize: 12, color: Colors.WHITE_0 }}>
                  {t('assignment_ongoing')}
                </Text>
              </li>
              <li>
                <Text size="x-small" style={{ fontSize: 12, color: Colors.WHITE_0 }}>
                  {t('assignment_overdue')}
                </Text>
              </li>
              <li>
                <Text size="x-small" style={{ fontSize: 12, color: Colors.WHITE_0 }}>
                  {t('assignment_completed')}
                </Text>
              </li>
            </ul>
          }>
          <QuestionCircleFilled style={{ color: '#D4DFFC', fontSize: '16px' }} />
        </Tooltip>
      </>
    ),
    render: (_, record) => (
      <Stack>
        <Badge type={getAssignmentStatus(record.start_date, record.end_date, t).type}>
          {getAssignmentStatus(record.start_date, record.end_date, t).status}
        </Badge>
      </Stack>
    ),
    sorter: (a, b) =>
      getAssignmentStatus(a.start_date, a.end_date, t).type?.localeCompare(
        getAssignmentStatus(b.start_date, b.end_date, t).type
      )
  },
  {
    key: 'action',
    title: '',
    render: (_, record) => {
      const { status } = getAssignmentStatus(record.start_date, record.end_date, t);
      return (
        <Stack spacing={20} justifyContent="flex-end">
          {status !== t('starting_soon') && (
            <Link
              onClick={() => {
                viewAssignmentReport(record);
              }}>
              {t('view_report')}
            </Link>
          )}
          <Tooltip color="purple" title={t('edit_assignment')}>
            <Edit
              style={{ width: 20, cursor: 'pointer', color: Colors.VIOLET }}
              onClick={() => editAssignmentId(record.id)}
              size={20}
              strokeWidth={2.5}
            />
          </Tooltip>
        </Stack>
      );
    }
  }
];

const AssignmentsTable = ({ assignments }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });
  const navigate = useNavigate();

  const editAssignmentId = id => {
    navigate(`/assignments/${id}`);
  };

  const viewAssignmentReport = item => {
    navigate(`/assignments/report/${item.id}`, { state: item });
  };

  return (
    <Table
      showSorterTooltip={false}
      pagination={{ pageSize: 5, hideOnSinglePage: true }}
      columns={columns(t, viewAssignmentReport, editAssignmentId)}
      dataSource={assignments}
    />
  );
};

export default AssignmentsTable;

AssignmentsTable.defaultProps = {
  assignments: []
};

AssignmentsTable.propTypes = {
  assignments: PropTypes.array
};
