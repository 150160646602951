import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { Form, Checkbox, message } from 'antd';
import { EducatorButton, EducatorInputField, HelperBot } from '../../commonComponents';
import { Navbar, MultistepParent } from '../../layoutComponents';
import { GOOGLE_SSO_AUTH_URL, USER_ROLE } from '@utils';
import googleImage from '../../../assets/images/googleImage.png';

import './parentPersonalDetails.css';

const ParentPersonalDetails = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({ fname: '', lname: '', email: '', password: '' });
  const [buttonSpinner, setButtonSpinner] = useState(false);
  const [refer, setRefer] = useState('');
  const [isCheckBox, setIsCheckBox] = useState(false);
  const [googleSpinner, setGoogleSpinner] = useState(false);

  const parentCreateAccount = () => {
    if (isCheckBox) {
      setButtonSpinner(true);
      const username = data.email.replace('@', '_');
      if (refer !== '') {
        Auth.signUp({
          username: username,
          password: data.password,
          attributes: {
            preferred_username: username,
            email: data.email,
            given_name: data.fname,
            family_name: data.lname,
            'custom:user_role': `${USER_ROLE.PARENT}`,
            'custom:referral_identifier': refer
          }
        })
          .then(() => {
            setButtonSpinner(false);
            navigate('/parent-create-account/email-confirmation', { state: { ...data, username: username } });
          })
          .catch(err => {
            message.error(`${err.message}`);
            setButtonSpinner(false);
          });
      } else {
        Auth.signUp({
          username: username,
          password: data.password,
          attributes: {
            preferred_username: username,
            email: data.email,
            given_name: data.fname,
            family_name: data.lname,
            'custom:user_role': `${USER_ROLE.PARENT}`
          }
        })
          .then(() => {
            setButtonSpinner(false);
            navigate('/parent-create-account/email-confirmation', { state: { ...data, username: username } });
          })
          .catch(err => {
            message.error(`${err.message}`);
            setButtonSpinner(false);
          });
      }
    } else {
      setButtonSpinner(false);
      message.error('First Select the Terms & Conditions');
    }
  };

  const onChangeHandle = e => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const googleSignIn = () => {
    setGoogleSpinner(true);
    window.location.href = GOOGLE_SSO_AUTH_URL;
  };

  useEffect(() => {
    const data = location.search.slice(1);
    setRefer(data);
  }, []);

  const onChangedCheck = event => {
    const { checked } = event.target;
    setIsCheckBox(checked);
  };
  return (
    <div className="mainDivSignUpStyle  container-fluid">
      <Navbar />
      <div className="row pt-4 pb-5">
        <div className="col-3  d-flex justify-content-center">
          <MultistepParent select="Personal Details" check={[0, 0, 0, 0, 0]} />
        </div>
        <div className="col-6 col-md-5 col-lg-6  d-flex justify-content-center">
          <Form onFinish={parentCreateAccount} initialValues={{ check: true }}>
            <div className="textStyleHeading">Create Your Parent Account</div>
            <div className="aboutTextStyle">Let us know a bit about yourself to get started.</div>
            <div className="pt-4 inlineDiv">
              <Form.Item name="fname" rules={[{ required: true, message: 'Please input your First Name!' }]}>
                <EducatorInputField
                  label="First Name:"
                  name="fname"
                  type="text"
                  inputValue={data.fname}
                  small
                  onChange={onChangeHandle}
                />
              </Form.Item>
              <Form.Item name="lname" rules={[{ required: true, message: 'Please input your Last Name!' }]}>
                <EducatorInputField
                  label="Last Name:"
                  name="lname"
                  type="text"
                  inputValue={data.lname}
                  small
                  onChange={onChangeHandle}
                />
              </Form.Item>
            </div>
            <Form.Item className="pt-3" name="email" rules={[{ required: true, message: 'Please input your Email!' }]}>
              <EducatorInputField
                label="Email:"
                type="email"
                name="email"
                inputValue={data.email}
                onChange={onChangeHandle}
              />
            </Form.Item>
            <Form.Item
              className="pt-3"
              name="password"
              rules={[
                { required: true, message: 'Please input your Password!' },
                {
                  required: true,
                  pattern: new RegExp(/^(?=.*\d)(?=.*?[@$!%*#?&^_.,-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/),
                  message:
                    'Must contain Number , Special Character , upper case letter, lower case letter, min length 8'
                }
              ]}>
              <EducatorInputField
                label="Password:"
                type="password"
                passwordValue={data.password}
                name="password"
                onChange={onChangeHandle}
              />
            </Form.Item>

            <div className="textStyle400Cabin18">
              <div className="displayInline my-3">
                <Checkbox onChange={onChangedCheck} />
                <div>
                  &nbsp; By signing up you agree to our
                  <span className="labelStyle cursorPointer">
                    {' '}
                    <a
                      href="https://www.cyberlegends.com/terms-and-conditions"
                      className="labelStyle"
                      target="_blank"
                      rel="noreferrer">
                      Terms & Conditions
                    </a>
                  </span>
                  &nbsp;and&nbsp;
                  <span className="labelStyle cursorPointer">
                    {' '}
                    <a
                      href="https://www.cyberlegends.com/privacy-policy"
                      className="labelStyle"
                      target="_blank"
                      rel="noreferrer">
                      Privacy Policy.
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <Form.Item>
              <EducatorButton label="Create Account" type="submit" showSpinner={buttonSpinner} />
            </Form.Item>
            <div className="textStyle400Cabin18">or</div>
            <div className="pt-2">
              <EducatorButton
                label="Sign up with Google "
                className="googleStyle"
                onClick={googleSignIn}
                showSpinner={googleSpinner}
                type="button"
                icon={<img src={googleImage} alt="googleLogo" width={40} height={40} />}
              />
            </div>
          </Form>
        </div>
        <div className="col-3 pb-4 d-flex align-items-end">
          <HelperBot>
            This account gives you access to the PARENT PORTAL. Keep your password safe! <br />
            <br />
            After you click &quot;Create Account&quot; you’ll verify your email and answer a few questions to get your
            kid(s) set to play the game!
          </HelperBot>
        </div>
      </div>
    </div>
  );
};
export default ParentPersonalDetails;
