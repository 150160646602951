import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { AuthenticatedNavbar } from '@components/layoutComponents';
import { useAppState } from '@context';
import { useAssignments } from '@hooks';
import { Colors, Card, Title, Button, Text, Stack } from '@teacher-app/design-system';

import NoAssignments from './NoAssignments';
import AssignmentsTable from './AssignmentsTable';
import dayjs from 'dayjs';

const Assignments = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });
  const navigate = useNavigate();
  const { state } = useAppState();

  const { data: classAssignmentsData, revalidate: getAssignments, loading } = useAssignments(state.classId);

  const currentAssignments = classAssignmentsData?.filter(
    assignment => assignment.end_date >= dayjs().format('YYYY-MM-DD')
  );
  const pastAssignments = classAssignmentsData?.filter(
    assignment => assignment.end_date < dayjs().format('YYYY-MM-DD')
  );

  const createAssignment = () => {
    navigate('/assignments/new');
  };

  const editAssignmentId = id => {
    navigate(`/assignments/${id}`);
  };

  useEffect(() => {
    getAssignments();
  }, [state.classId]);

  return (
      <div className="container-fluid p-2 p-md-3 p-lg-4 overViewMianDivHeight">
        <AuthenticatedNavbar title={t('assign_learning_topics')} />
        <Stack direction="column" alignItems="flex-start" spacing={30} style={{ marginTop: 30 }}>
              {loading ? (
                  <Stack direction="column" justifyContent="center" style={{ minHeight: '400px' }}>
                    <Spin size="large" />
                  </Stack>
              ) : currentAssignments?.length === 0 ? (
                <NoAssignments
                  showWhatsNew={pastAssignments?.length === 0}
                  onClick={() => {
                    editAssignmentId('new');
                  }}
                />
              ) : (
                <Card width='100%'>
                  <Stack direction='column' alignItems='flex-start' spacing={30}>
                    <Stack justifyContent='space-between'>
                      <Stack direction='column' alignItems='flex-start'>
                        <Title level={3}>{t('current_assignments')}</Title>
                        <Text size='small' style={{ color: Colors.GREY_100 }}>{t('current_assignments_detail')}</Text>
                      </Stack>
                      <Button onClick={createAssignment}>{t('assignment_create')}</Button>
                    </Stack>
                    <AssignmentsTable assignments={currentAssignments} />
                  </Stack>
                </Card>
              )}
              {pastAssignments?.length > 0 && (
                <Card width='100%'>
                  <Stack direction='column' alignItems='flex-start' spacing={30}>
                    <Stack direction='column' alignItems='flex-start'>
                      <Title>{t('past_assignments')}</Title>
                      <Text>{t('past_assignments_detail')}</Text>
                    </Stack>
                    <AssignmentsTable assignments={pastAssignments} />
                  </Stack>
                </Card>
              )}
        </Stack>
      </div>
  );
};
export default Assignments;
