import React, { useState } from 'react';
import { Image, Spin, Tooltip } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { Edit, Key, UserPlus, UserCheck } from 'react-feather';

import { useAppState } from '@context';
import { Card, Colors, Table, Title, Text, Stack } from '@teacher-app/design-system';

import { useStudents } from '@hooks';
import { QuestionCircleFilled } from '@ant-design/icons';
import notFoundImage from '@assets/images/icon_makeAccountWebsite.svg';
import LinkParentModal from '../LinkParentModal';
import StudentLoginDetailsModal from '../StudentLoginDetailsModal';
import EditStudentDetailsModal from '../EditStudentDetailsModal';

const columns = (t, setCurrentEditStudent, setCurrentLinkParents, setCurrentLoginDetails) => [
  {
    title: (
      <>
        {t('student_name')}&nbsp;
        <Tooltip color="purple" title={t('student_name_tooltip')}>
          <QuestionCircleFilled style={{ color: '#D4DFFC', fontSize: '16px' }} />{' '}
        </Tooltip>
      </>
    ),
    dataIndex: 'student_name',
    key: 'student_name',
    showSorterTooltip: false,
    sorter: (a, b) => a?.student_name?.localeCompare(b?.student_name)
  },
  {
    title: t('student_id'),
    dataIndex: 'external_id',
    key: 'student_id'
  },
  {
    title: t('student_email'),
    dataIndex: 'student_email',
    key: 'student_email'
  },
  {
    title: t('grade'),
    dataIndex: 'student_grade',
    key: 'student_grade'
  },
  {
    dataIndex: 'action',
    key: 'action',
    render: (_, { student }) => (
      <Stack spacing={20} justifyContent="flex-end" style={{ flexWrap: 'wrap' }}>
        <Tooltip color="purple" title={t('link_parents')}>
          {student.parent_connected ? (
            <UserCheck size={20} style={{ cursor: 'not-allowed', color: Colors.GREY_0, flex: '1 1 20px' }} />
          ) : (
            <UserPlus
              size={20}
              style={{ cursor: 'pointer', color: Colors.VIOLET, flex: '1 1 20px' }}
              onClick={() => setCurrentLinkParents(student)}
            />
          )}
        </Tooltip>
        <Tooltip color="purple" title={t('student_login_details')}>
          <Key
            style={{ cursor: 'pointer', color: Colors.VIOLET, flex: '1 1 20px' }}
            onClick={() => setCurrentLoginDetails(student)}
            size={20}
          />
        </Tooltip>
        <Tooltip color="purple" title={t('edit_student')}>
          <Edit
            style={{ cursor: 'pointer', color: Colors.VIOLET, flex: '1 1 20px' }}
            onClick={() => setCurrentEditStudent(student)}
            size={20}
          />
        </Tooltip>
      </Stack>
    )
  }
];

const StudentsList = () => {
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });
  const { state } = useAppState();

  const [currentEditStudent, setCurrentEditStudent] = useState(null);
  const [currentLoginDetails, setCurrentLoginDetails] = useState(null);
  const [currentLinkParents, setCurrentLinkParents] = useState(null);

  const { data: students, loading: studentsLoading, revalidate: getStudents } = useStudents(state.classId, '');

  const data =
    students &&
    students.map(student => {
      return {
        student_id: student.id,
        student_name: `${student.first_name} ${student.last_name}.`,
        student_grade: student.grade,
        student_email: student.student_email,
        parent_email: student.parent_email,
        external_id: student.external_id,
        student: student
      };
    });

  const handleCurrentEditStudent = student => {
    setCurrentEditStudent(student);
  };

  return (
    <>
      <Card>
        <Stack direction="column" alignItems="flex-start">
          <Title level={3}>{t('student_list')}</Title>
          {studentsLoading ? (
            <Stack style={{ paddingTop: 40, minHeight: 160 }} justifyContent="center">
              <Spin size="large" />
            </Stack>
          ) : students?.length === 0 ? (
            <Stack
              style={{ paddingTop: 40, paddingBottom: 20, minHeight: 160 }}
              direction="column"
              justifyContent="center">
              <Image src={notFoundImage} preview={false} />
              <Title style={{ textAlign: 'center' }} level={3}>
                {t('empty_here')}
              </Title>
              <Text style={{ textAlign: 'center' }} size="small">
                {t('empty_here_detail')}
              </Text>
            </Stack>
          ) : (
            students && (
              <Stack style={{ paddingBottom: 20 }} direction="column" alignItems="flex-start" spacing={30}>
                <Trans>
                  <Text size="small">
                    {t('students_subtext', { noOfStudents: students.length, text: students.length === 1 ? '' : 's' })}
                  </Text>
                </Trans>
                <Table
                  columns={columns(t, handleCurrentEditStudent, setCurrentLinkParents, setCurrentLoginDetails)}
                  dataSource={data}
                  pagination={{ position: ['bottomCenter'], defaultPageSize: 15, hideOnSinglePage: true }}
                />
              </Stack>
            )
          )}
        </Stack>
      </Card>
      <StudentLoginDetailsModal
        isOpen={currentLoginDetails}
        onClose={() => setCurrentLoginDetails(false)}
        selectedLoginDetails={currentLoginDetails}
      />
      <EditStudentDetailsModal
        isOpen={!!currentEditStudent}
        selectedStudent={currentEditStudent}
        onClose={() => {
          setCurrentEditStudent(null);
        }}
        handleOnFinish={() => {
          getStudents();
          setCurrentEditStudent(null);
        }}
      />
      <LinkParentModal
        isOpen={!!currentLinkParents}
        selectedStudent={currentLinkParents}
        onClose={() => setCurrentLinkParents(null)}
        handleOnFinish={() => {
          getStudents();
          setCurrentLinkParents(null);
        }}
      />
    </>
  );
};

export default StudentsList;
