import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Input, Result, Select, Spin, Table, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { AuthenticatedNavbar } from '@components/layoutComponents';

import './AssignmentsStyle.scss';
import BackgroundColor from '@components/commonComponents/BackgroundColor';
import { getApiWithAuth } from '@utils';
import { getAssignmentPercent, getAssignmentStatus } from '@pages/Assignments/common';

import notFoundImage from '@assets/images/icon_researchPaperBlack.svg';
import babyImage from '@assets/images/educatorTeacherImage.png';
import { QuestionOutlined, SearchOutlined } from '@ant-design/icons';
import { ClassResultPdfModal, IndividualResultPdfModal } from '@pages/Assignments/PdfModals';
import { useAppState } from '@context';

const { Option } = Select;

const reportStudentColumns = t => [
  {
    title: (
      <div className="d-flex flex-row gap-2">
        <div>{t('learning_goals')}</div>
        <Tooltip color="purple" title={t('learning_goal_tooltip')}>
          <Button
            className="tooltip-button"
            type="primary"
            shape="circle"
            size="small"
            icon={<QuestionOutlined size="small" />}
          />
        </Tooltip>
      </div>
    ),
    dataIndex: 'learning_goal',
    key: 'learning_goal'
  },
  {
    title: t('outcome'),
    key: 'outcome',
    render: (_, record) => (
      <div>
        {record.score}/{record.max_score}
      </div>
    )
  },
  {
    title: t('result'),
    key: 'result',
    render: (_, record) => {
      const { percent, textColor, backgroundColor } = getAssignmentPercent(record.score, record.max_score);

      return (
        <div className="tag-topic">
          <BackgroundColor title={`${percent} %`} background={backgroundColor} textColor={textColor} />
        </div>
      );
    }
  }
];

const reportColumns = t => [
  {
    title: t('student'),
    dataIndex: 'user',
    key: 'student'
  },
  {
    title: (
      <div className="d-flex flex-row gap-2">
        <div>{t('outcome')}</div>
        <Tooltip color="purple" title={t('outcome_tooltip')}>
          <Button
            className="tooltip-button"
            type="primary"
            shape="circle"
            size="small"
            icon={<QuestionOutlined size="small" />}
          />
        </Tooltip>
      </div>
    ),
    key: 'outcome',
    render: (_, record) => {
      let max_score = 0;
      let score = 0;
      record.assessments.forEach((assessment, i) => {
        score += assessment.score / (i + 1);
        max_score += assessment.max_score / (i + 1);
      });
      return record.status.toUpperCase() === 'COMPLETED' ? (
        <div>
          {score}/{max_score}
        </div>
      ) : (
        <div>Pending</div>
      );
    }
  },
  {
    title: t('result'),
    key: 'result',
    render: (_, record) => {
      const { percent, textColor, backgroundColor } =
        record.assessments.length > 0
          ? getAssignmentPercent(record.assessments[0].score, record.assessments[0].max_score)
          : getAssignmentPercent(null, null);

      return (
        <div className="tag-topic">
          <BackgroundColor title={`${percent} %`} background={backgroundColor} textColor={textColor} />
        </div>
      );
    }
  }
];

export const AssignmentReport = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'assignments' });

  const { id } = useParams();
  const { state } = useAppState();
  const currentClass = state.classes.find(item => item.id === state.classId);

  const [showClassResultPdf, setShowClassResultPdf] = useState(false);
  const [showIndividualResultPdf, setShowIndividualResultPdf] = useState(false);
  const [loadingReport, setLoadingReport] = useState(true);
  const [loadingDetail, setLoadingDetail] = useState(true);
  const [loadingStudent, setLoadingStudent] = useState(false);
  const [reports, setReports] = useState();
  const [filteredReport, setFilteredReport] = useState();
  const [detail, setDetail] = useState();
  const [student, setStudent] = useState();
  const [studentProgress, setStudentProgress] = useState('');
  const [searchKey, setSearchKey] = useState('');

  const getAssignmentReport = async () => {
    const { success, data } = await getApiWithAuth(`school/assignments/${id}/assessments`);
    if (success) {
      setLoadingReport(false);
      setReports(data);
    } else {
      setLoadingReport(false);
    }
  };

  const getAssignmentDetail = async () => {
    const { success, data } = await getApiWithAuth(`school/assignments/${id}`);
    if (success) {
      setLoadingDetail(false);
      setDetail(data);
    } else {
      setLoadingDetail(false);
    }
  };

  const handleSearch = e => {
    const { value } = e.target;
    setSearchKey(value);
  };

  useEffect(() => {
    getAssignmentReport();
    getAssignmentDetail();
  }, [id]);

  useEffect(async () => {
    if (studentProgress) {
      setLoadingStudent(true);
      const { success, data } = await getApiWithAuth(`/school/assessments/progress/${studentProgress}`);
      if (success) {
        setLoadingStudent(false);
        setStudent(data);
      } else {
        setLoadingStudent(false);
      }
    }
  }, [studentProgress]);

  useEffect(async () => {
    if (reports) {
      if (searchKey !== '') {
        setFilteredReport(reports.filter(report => report.user.toLowerCase().includes(searchKey.toLowerCase())));
      } else {
        setFilteredReport(reports);
      }
    }
  }, [searchKey, reports]);

  const selectStudent = studentId => {
    setStudentProgress(studentId);
  };

  const {
    status: assignmentStatus,
    textColor: assignmentTextColor,
    backgroundColor: assignmentBackgroundColor
  } = detail ? getAssignmentStatus(detail.start_date, detail.end_date, t) : getAssignmentStatus(null, null, t);

  const {
    percent: studentPercent,
    textColor: studentTextColor,
    backgroundColor: studentBackgroundColor
  } = student ? getAssignmentPercent(student.score, student.max_score) : getAssignmentPercent(null, null);

  return (
    <>
      {showClassResultPdf && reports && detail && (
        <ClassResultPdfModal
          reports={reports}
          details={detail}
          currentClass={currentClass}
          isOpen={showClassResultPdf}
          onClose={() => setShowClassResultPdf(false)}
        />
      )}
      {showIndividualResultPdf && student && detail && (
        <IndividualResultPdfModal
          student={student}
          details={detail}
          currentClass={currentClass}
          isOpen={showIndividualResultPdf}
          onClose={() => setShowIndividualResultPdf(false)}
        />
      )}
      <div className="container-fluid p-2 p-md-3 p-lg-4">
        <AuthenticatedNavbar title={`${detail ? detail.name : ''} ${t('report')}`} />
        <div className="row mt-4">
          <div className="col col-12 pb-3">
            <div>
              {loadingReport || loadingDetail ? (
                <div className="emptyMessageAssignment mt-4">
                  <Spin size="large" />
                </div>
              ) : !reports || !detail ? (
                <div className="not-found-page mt-1 pt-1">
                  <Result
                    icon={<img src={babyImage} alt="error" />}
                    status="error"
                    title={t('report_not_found')}
                    subTitle={t('report_not_found_detail')}
                  />
                </div>
              ) : (
                <div>
                  <div className="d-flex flex-column">
                    <div className="tag-topic">
                      <BackgroundColor
                        title={assignmentStatus}
                        background={assignmentBackgroundColor}
                        textColor={assignmentTextColor}
                      />
                    </div>
                    <div className="d-flex flex-row w-100 gap-3 mb-1">
                      <div className="assignmentTextMinStyle">Start: {detail.start_date}</div>
                      <div className="assignmentTextMinStyle">End: {detail.end_date}</div>
                    </div>
                  </div>
                  <div className="row justify-content-between">
                    <div className="emptyMessageAssignment col-md-5 col-12 col-lg-4 mt-4">
                      <div className="w-100">
                        <div className="d-flex mb-3 justify-content-between">
                          <div>
                            <div>
                              <div className="assignmentHeadingStyle">{t('class_result')}</div>
                              <div className="assignmentTextStyle">
                                {`${t('view')} ${t('or')} `}
                                <span
                                  className="text__link"
                                  onClick={() => {
                                    setShowClassResultPdf(true);
                                  }}>
                                  {`${t('print')} > `}{' '}
                                </span>
                                {`${t('your_class_report')}`}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={'mb-2'}>
                          <Input
                            prefix={<SearchOutlined />}
                            placeholder="Search"
                            value={searchKey}
                            onChange={handleSearch}
                            allowClear
                            className="fixCardInputField"
                          />
                        </div>
                        <div className={'w-100'}>
                          <Table pagination={{ pageSize: 10 }} columns={reportColumns(t)} dataSource={filteredReport} />
                        </div>
                      </div>
                    </div>
                    <div className="emptyMessageAssignment col-md-6 col-12 col-lg-7 mt-4">
                      <div className="w-100">
                        <div className="d-flex mb-3 justify-content-between">
                          <div className="w-100">
                            <div className="w-100">
                              <div className="d-flex flex-row gap-4 mb-3">
                                <div className="assignmentHeadingStyle">{t('result_for')}</div>
                                <div className="flex-grow-1">
                                  <Select
                                    onChange={selectStudent}
                                    value={studentProgress}
                                    className="fixCardInputField">
                                    {reports
                                      .filter(assingmentProgress => assingmentProgress.assessments.length > 0)
                                      .map(item => (
                                        <Option key={item.id} value={item.assessments[0].id}>
                                          <div className="d-flex flex-row justify-content-between  w-100 pt-2">
                                            <div>{item.user}</div>
                                            <div>
                                              <BackgroundColor
                                                title={item.grade}
                                                background={'#DDDDDD'}
                                                textColor={'#595D5C'}
                                              />
                                            </div>
                                          </div>
                                        </Option>
                                      ))}
                                  </Select>
                                </div>
                              </div>
                              <div className="assignmentTextStyle">
                                {`${t('select_student_for_view')} ${t('or')} `}
                                <span
                                  className={student ? 'text__link' : ''}
                                  onClick={() => {
                                    if (student) {
                                      setShowIndividualResultPdf(true);
                                    }
                                  }}>
                                  {`${t('print')} > `}
                                </span>
                                {`${t('their_report')}`}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          {loadingStudent ? (
                            <div className="emptyMessageAssignment mt-4">
                              <Spin size="large" />
                            </div>
                          ) : !student ? (
                            <div className="not-found-page">
                              <Result
                                icon={<img src={notFoundImage} alt="error" />}
                                status="error"
                                title={t('no_student_selected')}
                                subTitle={t('no_student_selected_detail')}
                              />
                            </div>
                          ) : (
                            <div className="w-100">
                              <div className="w-100 d-flex gap-4 justify-content-between">
                                <div className="flex-grow-1 assignmentSubHeadingStyle">{t('overall_result')}:</div>
                                <div className="assignmentTextStyle mt-1">
                                  {student?.score}/{student.max_score} Correct
                                </div>
                                <div>
                                  <BackgroundColor
                                    title={`${studentPercent} %`}
                                    background={studentBackgroundColor}
                                    textColor={studentTextColor}
                                  />
                                </div>
                              </div>
                              <div className="w-100">
                                <Table
                                  pagination={{ pageSize: 20 }}
                                  columns={reportStudentColumns(t)}
                                  dataSource={student ? student.results : []}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AssignmentReport;
