import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { useTranslation } from 'react-i18next';
import { LogOut, Settings } from 'react-feather';

import { API_URL, USER_ROLE } from '@utils';
import { useAppState } from '@context';
import { Colors, Stack, Text, Title } from '@teacher-app/design-system';
import * as Svg from '../../../utils/svg';
import { useNavigate } from 'react-router-dom';
import LogoutModal from '@teacher-app/components/LogoutModal/LogoutModal';
import useSWR from 'swr';

const rootSubmenuKeys = ['TeachingResources', 'Class'];

export const SidebarMenu = ({ selectedKeys, openKey, onClick }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'nav' });
  const [openKeys, setOpenKeys] = useState([openKey]);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const navigate = useNavigate();
  const { state } = useAppState();
  const { currentUser } = state;
  const { data: licenceInfo } = useSWR(API_URL.PAYMENTS.GET_LICENCE);

  const isOrganizationTeacher = licenceInfo?.data?.organization?.type === 'SCHOOL' || licenceInfo?.data?.organization?.type === 'DISTRICT';

  const teacherMenuItemDetails = [
    { key: 'Overview', icon: <Svg.HomeSvg />, label: t('overview'), className: 'second-step' },
    {
      key: 'TeachingResources',
      icon: <Svg.ResourcesSvg />,
      label: t('teaching_resources'),
      children: [
        { key: 'InClass', label: t('in_class'), className: 'third-step' },
        { key: 'Edubytes', label: t('edubytes'), className: 'fourth-step' }
      ]
    },
    {
      key: 'Class',
      icon: <Svg.ClassSvg />,
      label: t('class'),
      children: [
        { key: 'Students', label: t('students'), className: 'fifth-step' },
        { key: 'Assignments', label: t('assignments'), className: 'sixth-step' }
      ]
    },
    { key: 'Planner', icon: <Svg.CalendarSvg />, label: t('planner'), className: 'seventh-step' },
    { key: 'Support', icon: <Svg.SupportSvg />, label: t('support'), className: 'eighth-step' },
    !isOrganizationTeacher ? { key: 'ReferralsCredits', icon: <Svg.GiftSvg />, label: t('referrals'), className: 'ninth-step' } : {},
  ];

  const parentMenuItemDetails = [
    { key: 'Overview', icon: <Svg.HomeSvg />, label: t('overview') },
    { key: 'ChildAccounts', icon: <Svg.StudentSvg />, label: 'Child Accounts' },
    { key: 'Activities', icon: <Svg.ResourcesSvg />, label: 'Activities' },
    { key: 'Subscriptions', icon: <Svg.Card />, label: 'Subscriptions' },
    { key: 'Referrals', icon: <Svg.GiftSvg />, label: 'Referrals' }
  ];

  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    onOpenChange([openKey]);
  }, [openKey]);

  let items;

  switch (currentUser?.type) {
    case USER_ROLE.PARENT:
      items = parentMenuItemDetails;
      break;
    case USER_ROLE.TEACHER:
      items = teacherMenuItemDetails;
      break;
    case USER_ROLE.CHILD:
      items = [];
      break;
    default:
      items = [];
      break;
  }

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <Stack direction="column" justifyContent="space-between" style={{ marginBottom: 35 }}>
        <Menu
          selectedKeys={[selectedKeys]}
          defaultSelectedKeys={['Overview']}
          openKeys={openKeys}
          mode="inline"
          className="sideBarStyle sidebar__container"
          onClick={onClick}
          onOpenChange={onOpenChange}
          items={items}
        />
        <Stack style={{ padding: '20px 30px' }} spacing={20} justifyContent="center" direction="column">
          <Stack spacing={0} justifyContent="center" direction="column">
            <Title level={4} style={{ color: Colors.LAVENDER_50 }}>{`${
              currentUser?.first_name
            } ${currentUser?.last_name?.slice(0, 1)}.`}</Title>
            <Text size="x-small" ellipsis style={{ color: Colors.LAVENDER_50 }}>
              {currentUser?.email}
            </Text>
          </Stack>
          <Stack spacing={20} justifyContent="center">
            <Settings
              className="cursorPointer tenth-step"
              onClick={() => navigate('/settings')}
              color={Colors.LAVENDER_50}
            />
            <LogOut
              className="cursorPointer eleventh-step"
              onClick={() => setShowLogoutModal(true)}
              color={Colors.LAVENDER_50}
            />
          </Stack>
        </Stack>
      </Stack>
      <LogoutModal isOpen={showLogoutModal} onClose={() => setShowLogoutModal(false)} />
    </>
  );
};

SidebarMenu.defaultProps = {
  openKey: ''
};

SidebarMenu.propTypes = {
  selectedKeys: PropTypes.string.isRequired,
  openKey: PropTypes.string,
  onClick: PropTypes.func.isRequired
};
