import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, message, Skeleton } from 'antd';
import { Copy } from 'react-feather';
import { Button, Colors, Form, Modal, Stack, Text, Title } from '@teacher-app/design-system';
import { useLoginDetails } from '@hooks';
import { useTranslation } from 'react-i18next';

const StudentLoginDetailsModal = ({ isOpen, onClose, selectedLoginDetails }) => {
  const { data: loginDetails, loading } = useLoginDetails(selectedLoginDetails?.id);
  const { t } = useTranslation('teacher', { keyPrefix: 'students' });
  const [form] = Form.useForm();

  const copyToClipboard = async (classCode, key) => {
    try {
      await navigator.clipboard.writeText(classCode);
      message.success(t(`${key}_copied`));
    } catch (error) {
      message.error(t(`${key}_copy_failed`));
    }
  };

  useEffect(() => {
    if (!loading && selectedLoginDetails?.id) {
      form.setFieldsValue({
        username: loginDetails?.username,
        password: loginDetails?.password
      });
    }
  }, [selectedLoginDetails?.id, loading]);

  return (
    <Modal width={585} centered={false} onCancel={onClose} open={isOpen}>
      <Form
        form={form}
        initialValues={{ username: loginDetails?.username, password: loginDetails?.password }}>
        {loading ? (
          <Skeleton active />
        ) : (
          <Stack direction="column" spacing={22}>
            <Stack direction="column" alignItems='flex-start' spacing={12}>
              <Title level={3}>{t('student_login_details')}</Title>
              <Text size="small">{t('student_Login_details_subtext')}</Text>
              <Form.Item label={`${t('username')}:`} name="username">
                <Input
                  type="text"
                  name="username"
                  suffix={
                    <Copy
                      style={{ cursor: 'pointer', color: Colors.VIOLET }}
                      onClick={() => copyToClipboard(loginDetails?.username, 'username')}
                    />
                  }
                />
              </Form.Item>
              <Form.Item label={`${t('password')}:`} name="password">
                <Input
                  type="text"
                  name="password"
                  suffix={
                    <Copy
                      style={{ cursor: 'pointer', color: Colors.VIOLET }}
                      onClick={() => copyToClipboard(loginDetails?.password, 'password')}
                    />
                  }
                />
              </Form.Item>
            </Stack>
            <Button block onClick={onClose}>
              {t('got_it')}
            </Button>
          </Stack>
        )}
      </Form>
    </Modal>
  );
};

export default StudentLoginDetailsModal;

StudentLoginDetailsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedLoginDetails: PropTypes.object.isRequired
};
