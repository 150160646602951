/* eslint-disable react/jsx-fragments */
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { SWRConfig } from 'swr';

import reportWebVitals from './reportWebVitals';
import { AppStateProvider } from '@context';
import { ModalProvider } from '@context/modalContext';
import { fetcher } from '@utils';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import './utils/bugsnagClient';
import 'bootstrap/scss/bootstrap.scss';
import 'antd/dist/antd.min.css';
import 'dayjs/locale/fr-ca';
import './index.scss';
import './services/i18n';
import App from './App';

ReactDOM.render(
  <BrowserRouter>
    <SWRConfig
      value={{
        fetcher,
        shouldRetryOnError: false,
        revalidateOnFocus: false
      }}>
      <ErrorBoundary>
        <AppStateProvider>
          <ModalProvider>
            <App />
          </ModalProvider>
        </AppStateProvider>
      </ErrorBoundary>
    </SWRConfig>
  </BrowserRouter>,
  document.getElementById('root')
);

reportWebVitals();
