import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Empty, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import { getLocalStorageItem, USER_ROLE } from '@utils';
import { usePdfs, useStudents } from '@hooks';
import { useAppState } from '@context';
import { OnboardingVideoV2 } from '@components/commonComponents';
import { StudentModalManager } from '@components/commonComponents/Modals';
import { CardBlock } from '../CardBlock';

import rightArrow from '@assets/images/rightArrow.svg';

import './HelpfulLinks.scss';

const LinkItem = ({ link, title, description, onClick }) => {
  if (!link) {
    return (
      <li>
        <button onClick={onClick}>
          <div>
            {title}
            <p>{description}</p>
          </div>

          <img src={rightArrow} alt="rightArrow" />
        </button>
      </li>
    );
  }

  return (
    <li>
      <a href={link} onClick={onClick} target="_blank" rel="noopener noreferrer">
        <div>
          {title}
          <p>{description}</p>
        </div>
        <img src={rightArrow} alt="right arrow" />
      </a>
    </li>
  );
};

LinkItem.defaultProps = {
  link: undefined,
  onClick: () => {}
};

LinkItem.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

const NoStudentFound = ({ openModal, setOpenModal }) => {
  const closeModal = () => {
    setOpenModal('');
  };

  return (
    <>
      <CardBlock className="helpful-links">
        <OnboardingVideoV2
          playing
          type="AddingStudents"
          title="Helpful Links"
          messageText="In order to view helpful links, you’ll need to add students to your class."
          videoFirst={false}
          link={
            <button
              className="dashboard-link"
              onClick={() => {
                setOpenModal('stepOne');
              }}>
              Add students &nbsp;
              <img src={rightArrow} alt="rightArrow" />
            </button>
          }
        />
      </CardBlock>
      {<StudentModalManager modal={openModal} setModal={setOpenModal} closeFn={closeModal} />}
    </>
  );
};

NoStudentFound.propTypes = {
  openModal: PropTypes.string.isRequired,
  setOpenModal: PropTypes.func.isRequired
};

export const HelpfulLinks = ({ handleClickInviteParents }) => {
  const { t } = useTranslation('translation');
  const { state } = useAppState();
  const role = getLocalStorageItem('user_role');

  const { data, loading, isEmpty, revalidate } = usePdfs();
  const { data: students } = useStudents(state.classId);

  const [openModal, setOpenModal] = useState('');

  const hasStudent = students && students.length > 0;

  useEffect(() => {
    revalidate();
  }, [students.length]);

  if (loading) {
    return (
      <CardBlock className="helpful-links">
        <Skeleton active />
      </CardBlock>
    );
  }

  if (isEmpty && hasStudent) {
    return (
      <CardBlock className="helpful-links">
        <h3>{t('overview.helpful_links')}</h3>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'No links available.'} />
      </CardBlock>
    );
  }

  if (isEmpty && !hasStudent) {
    return <NoStudentFound openModal={openModal} setOpenModal={setOpenModal} />;
  }

  return (
    <>
      <CardBlock className="helpful-links">
        <h3>{t('overview.helpful_links')}</h3>
        <ul>
          {role === USER_ROLE.TEACHER ? (
            <>
              {data.parentLetter ? (
                <LinkItem
                  link={data.parentLetter.link}
                  title={t('overview.parent_letter')}
                  description={t('overview.parent_resource')}
                />
              ) : null}

              {data.accountDetails ? (
                <LinkItem
                  link={data.accountDetails.link}
                  title={t('overview.student_account_details')}
                  description={t('overview.login_credentials')}
                />
              ) : null}

              {data.loginInstructions ? (
                <LinkItem
                  link={data.loginInstructions.link}
                  title={t('students.login_instructions_for_students')}
                  description={t('students.help_students_sign_in')}
                />
              ) : null}

              {handleClickInviteParents ? (
                <LinkItem
                  title={t('overview.invite_parents')}
                  description={t('overview.invite_parents_update')}
                  onClick={handleClickInviteParents}
                />
              ) : null}
              <li className={'d-none'}>
                <a href="">
                  <div>
                    Unit Plan
                    <p>A resource that tells you what to teach and in what order.</p>
                  </div>

                  <img src={rightArrow} alt="rightArrow" />
                </a>
              </li>
              <li className={'d-none'}>
                <a href="">
                  <div>
                    Curriculum PDF
                    <p>WE NEED A DESCRIPTION FOR THIS.</p>
                  </div>

                  <img src={rightArrow} alt="rightArrow" />
                </a>
              </li>
            </>
          ) : (
            <>
              {data.loginInstructions ? (
                <li>
                  <a href={data.loginInstructions.link} target="_blank" rel="noopener noreferrer">
                    <div>
                      Get Login Instructions
                      <p>Help your children sign in for the first time.</p>
                    </div>

                    <img src={rightArrow} alt="rightArrow" />
                  </a>
                </li>
              ) : null}

              {data.accountDetails ? (
                <li>
                  <a href={data.accountDetails.link} target="_blank" rel="noopener noreferrer">
                    <div>
                      Child Account Details
                      <p>Login credentials for your children.</p>
                    </div>

                    <img src={rightArrow} alt="rightArrow" />
                  </a>
                </li>
              ) : null}
            </>
          )}
        </ul>
      </CardBlock>
    </>
  );
};

HelpfulLinks.defaultProps = {
  handleClickInviteParents: null
};

HelpfulLinks.propTypes = {
  handleClickInviteParents: PropTypes.func
};
